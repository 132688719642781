import React from "react";
import { Link } from "gatsby";
import { kebabCase } from "lodash";

import { useDispatch } from "react-redux";
import { toggleModal, setEmail } from "../state/store";
import Author from "./post/author";
import Tags from "./post/tags";

export default function Feed({ heading, posts, authorImages }) {
  const dispatch = useDispatch();
  const images = {};
  authorImages.forEach((image) => {
    images[image.node.name] = image.node.childrenImageSharp[0].gatsbyImageData;
  });
  return (
    <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
        <div>
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            {heading}
          </h2>
          <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
            <p className="text-xl text-gray-500">
              Stay up to date with our latest publications.
            </p>
          </div>
        </div>
        <div className="mt-12 grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          {posts.map((post) => {
            const { fields, frontmatter } = post.node;
            return (
              <div key={frontmatter.title}>
                <Tags tags={frontmatter.tags} tagSlugs={fields.tagSlugs} />
                <Link to={fields.slug} className="block mt-4">
                  <p className="text-xl font-semibold text-gray-900">
                    {frontmatter.title}
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    {frontmatter.description}
                  </p>
                </Link>
                <Author
                  author={frontmatter.author}
                  date={frontmatter.date}
                  profilePicture={images[kebabCase(frontmatter.author)]}
                  readingTime={frontmatter.readingTime}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
