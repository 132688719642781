import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Pagination from "../components/pagination";
import { useSiteMetadata } from "../hooks";
import Container from "../components/container";
import Feed from "../components/feed";

const IndexTemplate = ({ data, pageContext }) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();

  const { currentPage, hasNextPage, hasPrevPage, prevPagePath, nextPagePath } =
    pageContext;

  const posts = data.allMdx.edges;
  const authorImages = data.allFile.edges;
  const pageTitle =
    currentPage > 0
      ? `Blog - Page ${currentPage} - ${siteTitle}`
      : `Blog - ${siteTitle}`;

  return (
    <Layout title={pageTitle} description={siteSubtitle}>
      <Container>
        <Feed heading="Blog" posts={posts} authorImages={authorImages} />
        <Pagination
          prevPagePath={prevPagePath}
          nextPagePath={nextPagePath}
          hasPrevPage={hasPrevPage}
          hasNextPage={hasNextPage}
        />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query BlogTemplate {
    allMdx(
      limit: 4
      skip: 0
      filter: { frontmatter: { draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            tagSlugs
          }
          frontmatter {
            title
            date
            description
            author
            tags
            readingTime
          }
        }
      }
    }
    allFile(filter: { relativeDirectory: { eq: "people" } }) {
      edges {
        node {
          childrenImageSharp {
            gatsbyImageData(layout: CONSTRAINED, height: 400, width: 400)
          }
          name
        }
      }
    }
  }
`;

export default IndexTemplate;
